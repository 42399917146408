<template>
  <wx-open-launch-weapp
    id="launch-btn"
    username="gh_1d5c62b0b8f8"
    path="pages/home/home.html"
  >
    <!-- <script type="text/wxtag-template">
      <style>.btn { padding: 12px }</style>
      <button class="btn">打开小程序</button>
    </script> -->
  </wx-open-launch-weapp>
</template>

<script>
export default {};
</script>

<style></style>
