import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/font.css";
import "./assets/less/window3.less";
import "./assets/less/mobile3.less";
import uniRegElement from "./regCustomElement";
uniRegElement();
const app = createApp({
  ...App,
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
});
app.config.warnHandler = () => null;
console.log(router);
app.use(router).mixin({
  inject: ["reactive"],
});
if (document.querySelector("#app").childNodes.length == 0) {
  app.mount("#app");
} else {
  app.mount("#app", false);
}
app.config.compilerOptions.isCustomElement = (tag) =>
  tag.startsWith("phone-") ||
  tag.startsWith("window-") ||
  tag.startsWith("common-");
