<template>
  <div class="test">
    <button @click="post($event)">post request</button>
  </div>
</template>

<script>
export default {
  name: "api-a",
  data() {
    return {};
  },
  methods: {
    post: function (event) {
      event.preventDefault();

      let formData = new FormData();
      formData.append("id", "sadsad");
      formData.append("avatar_square", 234);
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.axios
        .post(
          "https://localhost:443/api/e9b849a515a84327b424af7ccdbf2949/v1_0_0/category/all",

          formData,
          config
        )
        .then((res) => {
          // console.log(res)
        });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 16px;
}
.test {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  width: 200px;
  background-color: #fff;
}
</style>
