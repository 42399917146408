export const viWords = [
    "与世界奇遇",
    "找到所爱",
    "跨界",
    "自然的接触",
    "美的享受",
    "冰山下的特质",
    "优质服务",
    "审美",
    "价值观",
    "跨文化体验",
    "期待感",
    "挖掘潜能",
    "提升自我",
    "国际化成长",
    "博物百科",
    "空间想象力",
    "探索欲",
    "人文知识",
    "成长动力",
    "感知世界",
    "认知自我",
    "综合素质",
    "优质资源",
    "多元场景",
    "趣味学习",
    "社会实践",

];
export const normalWords = [
    "serendipity",
    "热情洋溢",
    "优质休闲时光",
    "发现这个世界",
    "独处",
    "协作",
    "创造",
    "探索",
    "多维立体",
    "团队",
    "自我",
    "1+1>2",
    "角色定位",
    "品质",
    "动机",
    "沉浸",
    "体验",
    "叠加场景",
    "STEAM",
    "PLB",
    "休闲",
    "度假",
    "想象力",
    "思维逻辑",
    "交互",
    "益智活动",
    "生活品味",
    "享受",
    "能力",
    "执行力",
    "行动力",
    "培养",
    "成长",
    "团队",
    "合作",
    "伙伴",
    "独立",
    "自信",
    "世界观",
    "人际",
    "社交",
    "火花",
    "青春",
    "天性",
    "探索",
    "美育",
    "教育",
    "德育",
    "热爱",
    "启蒙",
    "娱乐",
    "游戏",
    "独立",
    "自信",
    "创新",
    "专家",
    "全球",
    "成长",
    "愉悦感",
    "归属感",
    "思维",
    "开拓视野",
    "磨砺意志",
    "品格塑造",
    "创造性",
    "娱乐性",
    "能力培养",
    "生活品位",
    "社会交流",
    "自然探索",
    "兴趣实践",
    "体验式",
    "项目式",
    "服务式",
    "感恩教育",
    "注意力训练",
    "生活习惯",
    "时间管理",
    "抗压受挫",
    "工程能力",
    "逆向思维",
    "观察能力",
    "生存急救",
    "儿童心理",
    "社会体育",
    "学习动力",
    "树立目标",
    "增长见识",
    "户外拓展",
    "激发梦想",
    "生活习惯",
    "时间管理",
    "主题项目",
    "告别拖延",
    "世界公民",
    "学科", "外语", "语言", 
    "自然", "人文", "科学实验","户外运动", 
    "竞赛","国际竞赛",
    "研学", "游学", "知识", 
    "研学游", "访学", "研游", 
    "研学教育", "营地", "冬令营", 
    "夏令营", "少儿培训", "特训营", 
    "暑假", "寒假", "假期", "小长假", 
    "周末", "素质教育", "亲子", "兴趣爱好", 
    "特长班", "留学文书", "小语种", "出国",

];