import { createRouter ,createWebHistory} from 'vue-router'
import Apidemo from './components/apidemo.vue'
import QRCode from './components/Code.vue'
import toWx from './components/toWx.vue'
import UniPage from './UniPage.vue'
import pages from './index.config'
import Camptogo from './Camptogo.vue'

const routes = [
  {
    path: "/",
    name: "camptogo",
    component: Camptogo,
  },
  {
    path:"/:name",
    name:"unipage",
    component:UniPage,
    beforeEnter: (to, from, next) => {
      if (to.params.name in pages) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: "/",
    name: "Home",
    component: QRCode,
  },
  {
    path:"/wx",component:toWx,
  },
  {
    path:"/qrcode",component:QRCode
  },

  {
    path:"/internal/tools/category/update",component:Apidemo
  },
  {
    path:"/index.html/internal/tools/category/update",component:Apidemo
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
console.log("indextouter js");


