<template>
  <section
    :style="gSection"
    class="hp-slide day clearfix hp-slide-3"
    style="postion: relative"
  >
    <div class="map" :style="mapSize">
      <div class="wordCloud">
        <transition v-for="({ text }, i) of renderData" :key="i" name="fade1">
          <span
            class="wordCloudTag"
            :style="{
              color: color[i % color.length],
              ...renderContentEle[i].style,
            }"
            v-if="!renderContentEle[i].hide"
            >{{ text }}</span
          >
        </transition>
      </div>
      <img src="../assets/img/sec3map.png" :style="imgSize" alt="" />
      <div class="sec3Title w-s3t">
        <h1 align="center" id="ew" :style="transform" :class="fadeIn">
          营你所爱!
        </h1>
        <h2
          align="center"
          class="page3engtitle"
          :style="transform"
          :class="fadeIn"
        >
          Good things come to those who camp!
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
import { gSize, gSizeAndP } from "../assets/js/gStyle";
import { viWords, normalWords } from "../renderData/newThree";
const wordWithTag = [];
const point = 0;
// 卡上下词的位置
normalWords.slice(0, point).forEach((r, i) => {
  wordWithTag.push({
    text: r,
    index: i,
    show: false,
    vip: false,
  });
});
viWords.forEach((r, i) => {
  wordWithTag.push({
    text: r,
    index: point + i,
    show: false,
    vip: true,
  });
});
normalWords.slice(point).forEach((r, i) => {
  wordWithTag.push({
    text: r,
    index: i + point + viWords.length,
    show: false,
    vip: false,
  });
});
export default {
  inject: ["gSection"],
  data() {
    return {
      data: wordWithTag,
      color: ["#333333", "#487629", "#333333", "#93d500"],
      contentEle: wordWithTag.map(() => ({
        x: 0,
        y: 0,
        z: 0,
        style: {},
      })),
      direction: "-1",
      speed: 1000,
      animateId: 0,
      angle: -0.003,
      space: 50,
      maxAlpha: 0.8,
      maxScale: 2.5,
      scale: { x: 1.2, y: 1.1 },
    };
  },
  name: "e-pagethree",
  props: ["isPhone", "ipx", "hpx", "wpx", "fadeIn", "transform"],
  computed: {
    renderNum() {
      return this.isPhone ? 50 : 60;
    },
    renderData() {
      return this.data.slice(0, this.renderNum);
    },
    renderContentEle() {
      return this.contentEle.slice(0, this.renderNum);
    },
    width() {
      return this.isPhone
        ? window.innerWidth * 0.9
        : 1521 * this.ipx * this.scale.x;
    },
    height() {
      return this.isPhone
        ? window.innerHeight * 0.75
        : 730 * this.ipx * this.scale.y;
    },
    mapSize() {
      return this.isPhone
        ? {}
        : {
            ...gSize(
              1521 * this.ipx * this.scale.x,
              730 * this.ipx * this.scale.y
            ),
            position: "relative",
            top: "50%",
            "margin-top": 0,
            transform: `translateY(-50%)`,
          };
    },
    imgSize() {
      return this.isPhone ? {} : gSize(752 * this.ipx, this.ipx * 728);
    },
    unVisibleR() {
      return this.isPhone
        ? (window.innerWidth * 0.9 * 0.7) / 2 - 30
        : (this.ipx * 640) / 2 - 30;
    },
    perspective() {
      return 1000 * Math.max(this.hpx, this.wpx);
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    ipx() {
      this.init();
    },
    contentEle() {
      const eles = [];
      for (let i = 0; i < this.contentEle.length; i++) {
        const hidden = this.contentEle[i].hide;
        if (hidden) {
          eles.push(i);
          this.data[i].show = false;
        }
      }
      const originHidden = this.data.filter((r) => !r.show);
      this.shuffle(originHidden);
      const vips = originHidden.filter((r) => r.vip);
      const normals = originHidden.filter((r) => !r.vip);
      let toShowEle = [];
      if (this.renderData.filter((r) => r.vip).length < this.renderNum * 0.6) {
        toShowEle = [].concat(vips, normals);
      } else {
        toShowEle = [].concat(normals, vips);
      }
      for (let i = 0; i < eles.length; i++) {
        this.data[eles[i]] = toShowEle[i];
        toShowEle[i].show = true;
      }
      this.data = this.data.slice();
    },
  },
  methods: {
    gSize,
    gSizeAndP,
    init() {
      window.cancelAnimationFrame(this.animateId);
      const RADIUSX = (this.width - 50) / 2;
      const RADIUSY = (this.height - 50) / 2;
      this.contentEle = [];
      for (let i = 0; i < this.renderData.length; i++) {
        this.data[i].show = true;
        const k = -1 + (2 * (i + 1) - 1) / this.renderData.length;
        const a = Math.acos(k);
        const b = a * Math.sqrt(this.renderData.length * Math.PI);
        const x = RADIUSX * Math.sin(a) * Math.cos(b);
        const y = RADIUSY * Math.sin(a) * Math.sin(b);
        const z = RADIUSX * Math.cos(a) * 0.9;
        const singleEle = {
          x,
          y,
          z,
          style: {},
        };
        this.contentEle.push(singleEle);
      }
      this.animate();
    },
    shuffle(pos) {
      let i = pos.length;
      while (i) {
        let j = Math.floor(Math.random() * i--);
        [pos[j], pos[i]] = [pos[i], pos[j]];
      }
    },
    animate() {
      this.rotateY();
      this.move();
      this.animateId = window.requestAnimationFrame(this.animate);
    },
    rotateX() {
      const angleX = this.angle;
      const cos = Math.cos(angleX);
      const sin = Math.sin(angleX);
      this.contentEle = this.contentEle.map((t) => {
        const y1 = t.y * cos - t.z * sin;
        const z1 = t.z * cos + t.y * sin;
        return {
          ...t,
          y: y1,
          z: z1,
        };
      });
    },
    shouldHide(x, y, z) {
      if (z > 0) return false;
      if (
        (x - this.width / 2) ** 2 + (y - this.height / 2) ** 2 <
        this.unVisibleR ** 2
      ) {
        return true;
      } else {
        return false;
      }
    },
    rotateY() {
      const angleY = this.angle;
      const cos = Math.cos(angleY);
      const sin = Math.sin(angleY);
      this.contentEle = this.contentEle.map((t, i) => {
        const x1 = t.x * cos - t.z * sin;
        const z1 = t.z * cos + t.x * sin;
        return {
          ...t,
          x: x1,
          z: z1,
        };
      });
    },
    move() {
      const CX = this.width / 2;
      const CY = this.height / 2;
      this.contentEle = this.contentEle.map((singleEle, i) => {
        const { x, y, z } = singleEle;
        const RADIUS = (this.width - this.space) / 2;
        const scale = this.perspective / (this.perspective - z);
        const alpha = Math.min(
          ((z + RADIUS) / (2 * RADIUS)) ** (z < 0 ? 1.5 : 1.2),
          this.maxAlpha
        );
        const left = x + CX - this.space / 2;
        const top = y + CY - this.space / 2;
        const transform = `translate3d(${left}px, ${top}px,0) scale(${
          (this.isPhone ? 0.8 : 1) * Math.min(scale, this.maxScale).toFixed(2)
        })`;
        const hide = this.shouldHide(left, top, z);
        const style = {
          ...singleEle.style,
          opacity: isNaN(alpha) ? 0 : alpha,
          "z-index": hide ? -1 : 0,
          transform,
        };
        return {
          x,
          y,
          z,
          style,
          hide,
        };
      });
    },
  },
};
</script>

<style>
.wordCloud {
  position: absolute;
  z-index: 1000;
  height: 100%;
  width: 100%;
  perspective: 1000px;
}
.wordCloudTag {
  display: block;
  white-space: nowrap;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  text-decoration: none;
  font-size: 0.8em;
  font-family: siyuan;
  font-weight: bold;
  transition: all 0.16s ease;
}
.fade1-enter-active,
.fade1-leave-active {
  transition: 0.16s;
}
.fade1-enter,
.fade1-leave-to {
  opacity: 0;
}
</style>
