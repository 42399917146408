<script>
import { getComponentName } from "./index.config";
import { h } from "vue";
export default {
  name: "index-root",
  created() {},
  render() {
    return h(getComponentName(this.$route.params.name));
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
body {
  width: 100vw;
  /* height: 100vh; */
}
</style>
