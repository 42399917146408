<template>
  <div class="code-zone">
    <div
      class="code-item code-item-1"
      :class="isPhone ? '' : 'fakeCodeWindow'"
      @mouseenter="showRealCodeIOS"
      @mouseleave="hideRealCodeIOS"
      @click="iosSpecial"
    >
      <div class="fakeCode" style="background-color: gray">
        <img src="../assets/QRCode/ios600.png" alt="" />
        <p class="download">iPhone/iPad下载 ></p>
      </div>
      <transition name="fade">
        <div class="realCode" v-show="!isPhone || isShowIOS">
          <div
            :class="isPhone ? '' : 'animate001'"
            :style="animation(isShowIOS)"
          >
            <div>敬请期待</div>
            <!-- <p style="color: #000">扫码下载</p>
            <img src="../assets/QRCode/iosDownload.png" alt="" /> -->
          </div>
        </div>
      </transition>
    </div>

    <div class="placeholder"></div>
    <div
      class="code-item code-item-2"
      :class="isPhone ? '' : 'fakeCodeWindow'"
      @mouseenter="showRealCodeWechat"
      @mouseleave="hideRealCodeWechat"
      @click="androidSpecial"
    >
      <div class="fakeCode">
        &nbsp;&nbsp;
        <img src="../assets/QRCode/wechat600.png" alt="" />
        <p class="download">&nbsp;&nbsp;Wechat小程序 ></p>
      </div>
      <transition name="fade">
        <div class="realCode" v-show="!isPhone || isShowWechat">
          <div
            :class="isPhone ? '' : 'animate001'"
            :style="animation(isShowWechat)"
          >
            <p style="color: #000">扫码进入</p>
            <img src="../assets/QRCode/wechatP.jpg" alt="" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "e-Code",
  props: ["isPhone"],
  data() {
    return {
      isShowIOS: false,
      isShowWechat: false,
      // 真实用户应该不会通过 devtools进行手机端的访问
      // 这部分代码没有 对 resize事件进行监听 后续有相关需求加上resize即可
      // 或者代码优化的时候把resize事件作为公共部分
    };
  },
  computed: {
    animation() {
      return (show) => {
        if (!this.isPhone) {
          return { transform: `scale(${show ? 1 : 0})` };
        }
        return {};
      };
    },
  },
  mounted() {},
  methods: {
    iosSpecial() {
      if (!this.isPhone) return;
      // this.isShowIOS=!this.isShowIOS;
      window.location.href =
        "itms-apps://apple.com/us/app/%E8%90%A5%E6%8E%A2/id1575920559";
    },
    androidSpecial() {
      if (!this.isPhone) return;
      this.isShowWechat = !this.isShowWechat;
    },
    showRealCodeIOS() {
      if (this.isPhone) {
        return;
        // window.location.href =
        //   "itms-apps://apple.com/us/app/%E8%90%A5%E6%8E%A2/id1575920559";
      } else {
        this.isShowIOS = true;
      }
    },
    created() {},
    hideRealCodeIOS() {
      if (!this.isPhone) this.isShowIOS = false;
    },
    showRealCodeWechat() {
      if (this.isPhone) {
        return;
        // this.isShowWechat = true;
        // location.href+="toWx";
        // wx.ready(function () {
        //   // var ua = navigator.userAgent.toLowerCase();
        //   // var isWXWork = ua.match(/wxwork/i) == "wxwork";
        //   // var isWeixin =
        //   //   !isWXWork && ua.match(/micromessenger/i) == "micromessenger";

        //   // if (isWeixin) {
        //     wx.config({
        //       debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        //       appId: "wx91df7b2bf3bb0572", // 必填，公众号的唯一标识
        //       timestamp: 123456, // 必填，生成签名的时间戳
        //       nonceStr: "camptogo", // 必填，填任意非空字符串即可
        //       signature: "camptogo", // 必填，填任意非空字符串即可
        //       jsApiList: ["showOptionMenu"], // 必填，随意一个接口即可
        //       openTagList: ["wx-open-launch-weapp"], // 填入打开小程序的开放标签名
        //     });
        //   // } else {
        //   //   alert("请在微信中打开");
        //   // }
        // });
      } else {
        this.isShowWechat = true;
      }
    },
    hideRealCodeWechat() {
      this.isShowWechat = false;
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 16px;
}
.code-zone {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.placeholder {
  padding: 20px;
}
.code-zone .code-item {
  /* width: 250px; */
  /* height: 100px; */
  text-align: center;
  margin-top: 30px;
}
.code-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}
.animate001 {
  transform-origin: bottom;
  transition: transform 0.5s ease;
}
.fakeCode {
  display: flex;
  flex-direction: columns;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  /* height: 60%; */
  background-color: #1a1a1a;
  border-radius: 20px;
  padding: 12px 10px;
}
.fakeCodeWindow:hover > .fakeCode {
  transform: scale(0.9);
  transition: transform 0.1s ease;
}
.code-zone .code-item .fakeCode {
  /* margin-top: 30px; */
}
.code-zone .code-item .fakeCode img {
  margin: auto;
  width: 40px;
  height: 40px;
}
.code-zone .code-item .realCode {
  position: absolute;
  /* margin-top: -180px; */
  top: -1px;
  transform: translateY(-100%);
  /* margin-left: 10px; */
}
.code-zone .code-item .realCode img {
  width: 144px;
  height: 144px;
}
p {
  /* margin-left: 10px; */
}
.download {
  white-space: nowrap;
  /* min-width: 150px; */
}
img,
p {
  display: block;
  color: #fff;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease-in-out;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@media only screen and (max-width: 483px) {
  .code-zone {
    /* display: flex;

    justify-content: space-around;
    align-items: center;
    padding: 20px 0; */
  }
  .fakeCode {
    padding: 10px 10px;
  }
  .code-zone .code-item {
    flex-direction: row;
  }
  p {
    font-size: 12px;
    /* min-width: 120px; */
  }
  .code-zone .code-item .realCode {
    /* margin-top: -155px;
    margin-left: 10px; */
  }
  .code-zone .code-item {
    width: 190px;
    height: auto;
  }
  .greenboxes {
    width: 20vw;
    /* height: ; */
  }
}
</style>
