
const sixCampImg = [
    [
      500,
      451,
      226,
      155,
      0,
      "日间营",
      "Day Camps",
      "想掌控自己的夜晚?",
      "没问题！把白天交给我们，无需在营地过夜就可体验丰富单日/多日活动。",
      [274, 127, 28, 526],//popup信息 同下
      [161, 93, 369, 496]//小标题信息width height left top
    ],
    [161, 117, 736, 204, 1, "", "", , , [1, 1, 1, 1], [1, 1, 1, 1]],
    [
      434, 
      438,
      1237,
      164,
      2,
      "单飞营",
      "Solo Camps",
      "想与孩子共度假期并各自成长?",
      "没问题！分开行动，各自享受属于自己同龄群体的营地生活。",
      [585, 244, 377, 127],
      [185, 76, 758, 664]
    ],
    [
      461,
      491,
      139,
      589,
      3,
      "亲子营",
      "Family Camps",
      "想建立理想的亲子关系?",
      "没问题！您与孩子在全新的场景下，共同认识这个奇妙的世界。",
      [385, 127, 37, 1211],
      [229, 130, 62, 805 - 50],
    ],
    [
      572,
      486,
      683,
      594,
      4,
      "主题营",
      "Theme Camps",
      "偏爱大自然，喜欢做研究，中意户外运动，乐于搞创作?",
      "没问题！丰富的主题产品供你选择。",
      [311, 160, 111, 1436],
      [192, 72, 227, 1611]
    ],
    [
      440,
      409,
      1297,
      625,
      5,
      "独立营",
      "Sleep-away Camps",
      "想把夜晚分享给伙伴?",
      "没问题！独立自信的品质将被唤醒，沟通力、合作力将有质的飞跃。",
      [326, 177, 646, 1348],
      [257, 78, 671, 1273]
    ],
  ];
  const sixCampImgP = [
    [398, 390, 86, 151],
    [140, 103, 295, 627],
    [446, 411, 534, 316],
    [377, 381, 34, 873],
    [603, 426, 133, 1285],
    [418, 373, 582, 900]
  ];
  // 4 是独立营 5是主题营 设计图差异
  export function renderCampImg(isPhone) {
    if (!isPhone) return sixCampImg;
    const ret = [];
    for (let i = 0; i < sixCampImgP.length; i++) {
      const current = [];
      current.push(
        ...sixCampImgP[i].slice(0),
        ...sixCampImg[i].slice(4)
      );
      ret.push(current);
    }
    return ret;
  }