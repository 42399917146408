<template>
  <section
    :style="gSection"
    class="hp-slide plane planeMobileCloudPatch clearfix"
  >
    <div
      v-for="[
        width,
        height,
        x,
        y,
        index,
        titleZH,
        titleEN,
        popTitle,
        popContent,
      ] in renderCampImg(isPhone)"
      :key="index"
      :class="'uniCampBox'"
      :style="gSizeAndP(width * ipx, height * ipx, x * wpx, y * hpx)"
      @click="popUpClick(index)"
    >
      <div
        :class="'uniCamp p2camp' + index"
        :style="
          currentPopup == index && index != 1 ? { transform: 'scale(1.1)' } : {}
        "
      ></div>
      <!-- [w1,h1,x1,y1], -->
      <!-- [w2,h2,x2,y2] -->
      <!-- :style="isPhone?gMsizeAndP(w2*wpx,h2*hpx,x2*wpx,y2*hpx):{}" -->
      <div class="p2subtitlebox" :class="'p2sub' + index">
        <p :class="fadeIn" :style="transform">{{ titleZH }}</p>
        <p :class="fadeIn" :style="transform">{{ titleEN }}</p>
      </div>
      <!-- 这部分可能存在一个问题popup和小标题重合  所以应该用相对布局好一些 但是蓝湖目前获取不到小标题和popup的位置信息(别的什么布局问题知道这个之后也能解决) 暂时先用绝对布局 -->
      <!-- :style="isPhone?gMsizeAndP(w1*wpx,h1*hpx,x1*wpx,y1*hpx):{}" -->
      <div
        v-if="currentPopup == index || !isPhone"
        class="uniP2popup"
        :class="'p2popup' + index"
        :style="
          isPhone
            ? {
                opacity: 1,
                animation:
                  index != 1
                    ? `fadeInLeftMobile${
                        haveProperityLeft.includes(index) ? '0' : '1'
                      } 1s cubic-bezier(.41,.41,.01,1.5) 1 forwards`
                    : 'none',
              }
            : {}
        "
        @click.stop="popUpClick(-1)"
      >
        <p>{{ popTitle }}</p>
        <p>{{ popContent }}</p>
      </div>
    </div>
    <div class="p2rocket" :style="rocketStyle" @click="speedUp"></div>
    <div class="page2titlebox">
      <h1 class="pagetitle page2title" :class="fadeIn" :style="transform">
        探你所寻!
      </h1>
      <p :class="fadeIn" :style="transform">The Future Is Now!</p>
    </div>
    <div v-if="isPhone" class="cloudBg" :style="pageSpecialCloudStyle"></div>
  </section>
</template>

<script>
import { renderCampImg } from "../renderData/two.js";
import { gSize, gSizeAndP } from "../assets/js/gStyle";
import { ideaScreen } from "../assets/js/ideaScreen";
export default {
  props: [
    "isPhone",
    "hpx",
    "wpx",
    "ipx",
    "fadeIn",
    "transform",
    "scrollTop",
    "cloudStyle",
    "cloudTop",
  ],
  name: "e-pagetwo",
  data() {
    return {
      currentPopup: -1,
      haveProperityLeft: [0, 4, 5],
      // transform属性被占用 用margin实现,数组中的项是有left属性的用margin-left属性实现
      phoneRocket: [
        169,
        1400,
        71.5,
        ideaScreen["phone"].width / 2,
        ideaScreen["phone"].height / 2,
      ],
      windowRocket: [
        695,
        853,
        77,
        ideaScreen["window"].width / 2,
        ideaScreen["window"].height / 2,
      ],
      // INCREASEMENT: 2.5,
      done: [],
      angle: 180,
      path: [],
      angleSpeed: 180 / Math.PI / 100,
      originSpeed: 180 / Math.PI / 100,
      index: 0,
      animateId: 0,
      speeduping: false,
      timerId: 0,
    };
  },

  computed: {
    gSection() {
      return `height:${window.innerHeight}px`;
    },
    //   可以增加set方法或者放在data里面如果考虑随机变化半径或半长轴的话
    pageSpecialCloudStyle() {
      return {
        ...this.cloudStyle,
        top: (this.cloudTop - ideaScreen["phone"].height) * this.hpx + "px",
      };
    },
    p() {
      return this.isPhone ? 740 : -69.5;
    },
    locus0a() {
      return this.isPhone ? 400 : 0;
    },
    locus0b() {
      return this.isPhone ? 400 : -350;
    },
    locus0r() {
      return this.isPhone ? 400 : 350;
    },
    locus1a() {
      return this.isPhone ? 420 : 850;
    },
    locus1b() {
      return this.isPhone ? 750 : 350;
    },
    origin() {
      return this.isPhone
        ? { x: 169, y: -1400 + this.phoneRocket[4] }
        : { x: 695, y: -853 + this.windowRocket[4] };
    },
    INCREASEMENT() {
      return this.angleSpeed * 10;
    },
    transformedAngle() {
      return (this.angle / 180) * Math.PI;
    },
    device() {
      return this[this.isPhone ? "phoneRocket" : "windowRocket"];
    },
    rocketSize() {
      return gSize(150 * this.ipx, 130 * this.ipx);
    },
    rocketStyle() {
      const positionAndAngle = this.device;
      return {
        ...this.rocketSize,
        transform: `rotate(${positionAndAngle[2]}deg) translate3d(0,0,0)`,
        top: `${positionAndAngle[1] * this.hpx}px`,
        left: `${positionAndAngle[0] * this.wpx}px`,
      };
    },
  },
  mounted() {
    if (!this.isPhone) {
      this.angle = 90;
    }
    window.cancelAnimationFrame(this.animateId);
    this.animateId = window.requestAnimationFrame(this.updateRocket);
  },
  methods: {
    speedUp() {
      // 0.2s左右的速度衰减
      if (this.speeduping) {
        return;
      } else {
        this.speeduping = true;
        this.angleSpeed *= 10;
        let i = 0.5;
        this.timerId = setInterval(() => {
          if (this.angleSpeed > this.originSpeed) {
            this.angleSpeed -= i * this.originSpeed;
          } else {
            clearInterval(this.timerId);
            this.speeduping = false;
          }
        }, 32);
      }
    },
    transformYCoordinate(topOrY, x, getPoint) {
      if (this.isPhone) {
        return -topOrY + this.device[4];
      } else {
        if (getPoint) {
          return {
            X: x - this.device[3],
            Y: 200 - topOrY,
          };
        } else if (typeof x == "number") {
          return {
            X: x + this.device[3],
            Y: 200 - topOrY,
          };
        } else {
          return 200 - topOrY;
        }
      }
    },
    getAngleFromatan(arctan) {
      return (Math.atan(arctan) * 180) / Math.PI;
    },
    getRocketDirectionFromTangent(tan, direction = 1) {
      if (tan < 0) tan += 180;
      return (180 - direction * tan) % 360;
    },
    up(point) {
      return (
        this.INCREASEMENT /
          (this.isPhone
            ? 1.5
            : 1.5 / (0.5 + Math.abs(point / this.device[4])) ** 2) +
        point
      );
    },
    down(point) {
      return point - this.INCREASEMENT;
    },
    move(point, up) {
      if (up) return this.up(point);
      return this.down(point);
    },
    locusStart(y) {
      // 竖直向上抛物线运动至0,900
      const pointY = this.up(this.transformYCoordinate(y));
      const pointX = pointY ** 2 / 2 / this.p;
      const pointAngle = this.getRocketDirectionFromTangent(
        this.getAngleFromatan(this.p / pointY)
      );
      this.updateDevice(pointX, this.transformYCoordinate(pointY), pointAngle);
    },
    locusStartWindow(y) {
      const pointY = this.up(this.transformYCoordinate(y));
      const pointX = -Math.sqrt(2 * this.p * pointY) || 0;
      const pointAngle = this.getRocketDirectionFromTangent(
        this.getAngleFromatan(pointX / this.p)
      );
      const { X, Y } = this.transformYCoordinate(pointY, pointX);
      this.updateDevice(X, Y, pointAngle);
    },
    locus0() {
      // 圆周运动至
      this.angle -= this.angleSpeed;
      const pointX =
        this.locus0a + Math.cos(this.transformedAngle) * this.locus0r;
      const pointY = this.locus0r * Math.sin(this.transformedAngle);
      const angle =
        this.getRocketDirectionFromTangent(
          this.getAngleFromatan((this.locus0a - pointX) / pointY)
        ) - (pointX > this.locus0a ? 180 : 0);
      this.updateDevice(pointX, this.transformYCoordinate(pointY), angle);
    },
    locus0Window() {
      // 圆周运动至
      this.angle -= this.angleSpeed;
      const pointX =
        this.locus0a + Math.cos(this.transformedAngle) * this.locus0r;
      const pointY =
        this.locus0b + this.locus0r * Math.sin(this.transformedAngle);
      const angle =
        this.getRocketDirectionFromTangent(
          this.getAngleFromatan(pointX / (this.locus0b - pointY))
        ) - (pointX > this.locus0a ? 180 : 0);
      const { X, Y } = this.transformYCoordinate(pointY, pointX);
      this.updateDevice(X, Y, angle);
    },
    locus1() {
      // 椭圆周运动
      this.angle -= this.angleSpeed / 1.5;
      const pointX =
        this.locus1a + this.locus1a * Math.cos(this.transformedAngle);
      const pointY = this.locus1b * Math.sin(this.transformedAngle);
      const angle =
        this.getRocketDirectionFromTangent(
          this.getAngleFromatan(
            ((this.locus1a - pointX) * this.locus1b ** 2) /
              (this.locus1a ** 2 * pointY)
          )
        ) - (pointX > this.locus1a ? 180 : 0);
      this.updateDevice(pointX, this.transformYCoordinate(pointY), angle);
    },
    locus1Window() {
      // 椭圆周运动
      this.angle -= this.angleSpeed / 2;
      const pointX = this.locus1a * Math.cos(this.transformedAngle);
      const pointY =
        this.locus1b * Math.sin(this.transformedAngle) - this.locus1b;
      const angle =
        this.getRocketDirectionFromTangent(
          this.getAngleFromatan(
            (pointX * this.locus1b ** 2) /
              ((-this.locus1b - pointY) * this.locus1a ** 2)
          )
        ) - (pointX > 0 ? 180 : 0);
      const { X, Y } = this.transformYCoordinate(pointY, pointX);
      this.updateDevice(X, Y, angle);
    },
    locus2(x, y) {
      // 直线运动
      const pointY = y + this.INCREASEMENT;
      this.updateDevice(x, pointY);
    },
    locusBackToOrigin() {
      // 内切圆运动
      //   待修改
      this.angle += this.angleSpeed * 1.25;
      const sqrt3 = Math.sqrt(3);
      const crossX = this.locus1a * 2;
      const crossY = ((crossX - this.origin.x) * sqrt3) / 3 + this.origin.y;
      const s = Math.sqrt(
        (crossX - this.origin.x) ** 2 + (crossY - this.origin.y) ** 2
      );
      const r = (s * sqrt3) / (2 + sqrt3);
      const Ox = crossX - r;
      const Oy = -((sqrt3 / 3) * (Ox - this.origin.x) + this.origin.y);
      const pointX = Ox + r * Math.cos(this.transformedAngle);
      const pointY = Oy + r * Math.sin(this.transformedAngle);
      const angle =
        this.getRocketDirectionFromTangent(
          this.getAngleFromatan((Ox - pointX) / (Oy - pointY))
        ) - (pointX > Ox ? 180 : 0);
      this.updateDevice(pointX, pointY + this.device[4], angle);
    },
    updateDevice(...splice) {
      this.device.splice(0, splice.length, ...splice);
    },
    updateRocket() {
      let [x, y, angle, halfWidth, halfHeight] = this.device;
      if (this.isPhone) {
        //   phone
        if (!this.done.includes(0)) {
          this.locusStart(y);
          if (y <= this.device[4] + 2) {
            this.done.push(0);
          }
        } else if (!this.done.includes(1)) {
          this.locus0();
          if (this.angle <= -180) {
            this.done.push(1);
          }
        } else if (!this.done.includes(2)) {
          this.locus1();
          if (this.angle < -540) {
            this.angle = 180;
            this.done.splice(1, this.done.length - 1);
          }
        }
        // 内切圆相关 暂时废弃
        // else if (!this.done.includes(3)) {
        //   this.locus2(x, y);
        //   const sqrt3 = Math.sqrt(3);
        //   const crossX = this.locus1a * 2;
        //   const crossY = ((crossX - this.origin.x) * sqrt3) / 3 + this.origin.y;
        //   const s = Math.sqrt(
        //     (crossX - this.origin.x) ** 2 + (crossY - this.origin.y) ** 2
        //   );
        //   const r = (s * sqrt3) / (2 + sqrt3);
        //   const Ox = crossX - r;
        //   const Oy = (sqrt3 / 3) * (Ox - this.origin.x) + this.origin.y;
        //   //Oy和locusBacktoOrigin里的是相反数
        //   if (y >= this.transformYCoordinate(Oy) - this.INCREASEMENT) {
        //     this.done.push(3);
        //   }
        // } else if (!this.done.includes(4)) {
        //   this.locusBackToOrigin();
        //   if (x < this.origin.x - 2) {
        //     this.angle = 180;
        //     this.done.splice(0, this.done.length);
        //   }
        // }
      } else {
        //   window
        if (!this.done.includes(0)) {
          this.locusStartWindow(y);
          if (y <= 200) {
            this.done.push(0);
          }
        } else if (!this.done.includes(1)) {
          this.locus0Window();
          if (this.angle <= -270) {
            this.done.push(1);
          }
        } else if (!this.done.includes(2)) {
          this.locus1Window();
          if (this.angle <= -630) {
            this.angle = 90;
            this.done.splice(1, this.done.length - 1);
          }
        }
      }
      this.animateId = window.requestAnimationFrame(this.updateRocket);
    },
    gSizeAndP,
    renderCampImg,
    popUpClick(i) {
      this.currentPopup = i;
    },
  },
};
</script>

<style></style>
