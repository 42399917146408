<template>
  <div id="home2">
    <div class="header" ref="header">
      <div class="yingtan">
        <img class="yingtanimg" src="./assets/white-yintan.png" alt="" />
      </div>
      <div class="header-upper">
        <div class="routerTest"></div>
      </div>
      <div class="header-lower"></div>
    </div>
    <!-- 合并五个滚动按钮  -->
    <button
      class="bt-down"
      :class="{ 'bt-up': nextSectionIndex === 0, cloudAbove: isAboveCloud }"
      @click="scrollToNextSection()"
    ></button>
    <div v-if="!isPhone" class="cloudBg" :style="cloudStyle"></div>
    <!-- 五个页面,若页面很多时可考虑虚拟列表原理优化 -->
    <PageOne
      :isPhone="isPhone"
      :fadeIn="gFadeIn(0)"
      v-bind="reactive"
      :cloudStyle="cloudStyle"
    />
    <PageTwo
      :isPhone="isPhone"
      v-bind="reactive"
      :fadeIn="gFadeIn(1)"
      :transform="gTransform(1)"
      :scrollTop="scrollTop"
      :cloudStyle="cloudStyle"
      :cloudTop="cloudTop"
    />
    <PageThree
      :isPhone="isPhone"
      v-bind="reactive"
      :fadeIn="gFadeIn(2)"
      :transform="gTransform(2)"
    />
    <PageFour
      :fadeIn="gFadeIn(3)"
      :transform="gTransform(3)"
      v-bind="reactive"
      :isPhone="isPhone"
    />
    <PageFive
      v-bind="reactive"
      :fadeIn="gFadeIn(4)"
      :isPhone="isPhone"
      :transform="gTransform(4)"
    />
  </div>
</template>

<script>
import PageOne from "./pages/new-One.vue";
import PageTwo from "./pages/two.vue";
// import PageThree from "./pages/three.vue";
import PageThree from "./pages/newThree.vue";
import PageFour from "./pages/four.vue";
import PageFive from "./pages/five.vue";
import { ideaScreen } from "./assets/js/ideaScreen.js";
import { gSizeAndP } from "./assets/js/gStyle";

const config = {
  translateY: 70,
  windowActionPoints: [
    [25, 75],
    [25, 75],
    [40, 60],
    [40, 60],
    [40, 60],
  ],
  phoneActionPoints: [
    [30, 70],
    [30, 70],
    [40, 60],
    [40, 60],
    [40, 60],
  ],
};
export default {
  name: "home2-m",
  components: {
    PageOne,
    PageTwo,
    PageThree,
    PageFour,
    PageFive,
  },
  provide: {
    gSection: `height:${window.innerHeight}px`,
  },
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
  data() {
    return {
      cloudPosition: 0,
      cloudMoveSpeed: 0.025,
      nextSectionIndex: 1,
      isPhone: /Android|webOS|iPhone|iPod|BlackBerry/i.test(
        navigator.userAgent
      ),
      screenH: window.innerHeight,
      screenW: window.innerWidth,
      currentFadeInPage: 0,
      scrollTop: 0,
      fadeState: new Array(5)
        .fill(0)
        .map((r, i) => new Set(["fadeIn-before", "fadeIn"])),
      //5在create 之前获取不到  只能手动更新
      transformState: new Array(5)
        .fill(0)
        .map((r, i) => (i == 0 ? [0, 1] : [config.translateY, 0])),
      p2Opacity: 0,
      cloudTop: 1457,
    };
  },
  created() {},
  computed: {
    isAboveCloud() {
      // 50是箭头半截入云
      return (
        this.scrollTop <=
        50 +
          (this.isPhone ? 463 : 402) * this.reactive.ipx -
          (ideaScreen[this.isPhone ? "phone" : "window"].height -
            (this.isPhone ? 1457 : 840)) *
            this.reactive.hpx
      );
    },
    cloudStyle() {
      return this.isPhone
        ? {
            ...gSizeAndP(
              (7633 / 2) * this.reactive.ipx,
              463 * this.reactive.ipx,
              0 * this.reactive.wpx,
              this.cloudTop * this.reactive.hpx
            ),
            "background-position": `${-this.cloudPosition}% 0`,
          }
        : {
            ...gSizeAndP(
              (7633 / 2) * this.reactive.ipx,
              402 * this.reactive.ipx,
              0 * this.reactive.wpx,
              840 * this.reactive.hpx
            ),
            "background-position": `${-this.cloudPosition}% 0`,
          };
    },
    gFadeIn() {
      return (i) =>
        i == 0 || i == 6 ? [...this.fadeState[i]].join(" ") : "transition02s";
    },
    len() {
      return document.querySelectorAll("section").length;
    },
    reactive() {
      const device = this.isPhone ? "phone" : "window";
      const wpx = this.screenW / ideaScreen[device].width;
      const hpx = this.screenH / ideaScreen[device].height;
      return {
        wpx,
        hpx,
        ipx: Math.min(wpx, hpx),
      };
    },
  },
  methods: {
    cloudMove() {
      if (this.cloudPosition <= -100) {
        this.cloudPosition = 0;
      }
      this.cloudPosition -= this.cloudMoveSpeed;
      window.requestAnimationFrame(this.cloudMove);
    },
    getPage() {
      const scrollHeight = this.scrollTop;
      const screen = window.innerHeight;
      if (scrollHeight < 0.95 * screen) {
        return 0;
      } else if (scrollHeight > (this.isPhone ? 3.05 : 3.01) * screen) {
        return 4;
      } else {
        return -1;
      }
    },
    gOpacity(opacity) {
      return { opacity };
    },
    gTransform(i) {
      const [translateY, opacity] = this.transformState[i];
      return { transform: `translate3d(0,${translateY}px,0)`, opacity };
    },
    transformSplice(page, ...newArrayItems) {
      const num = page < this.getLen() - 1 ? 2 : 1;
      this.transformState.splice(page, num, ...newArrayItems.slice(0, num));
    },
    updateTransform(page, scrollTop) {
      const [up, down] =
        config[this.isPhone ? "phoneActionPoints" : "windowActionPoints"][page];
      const { translateY } = config;
      // const assume100 = window.innerHeight * 0.3;
      if (scrollTop < (up / 100) * window.innerHeight) {
        this.transformSplice(page, [0, 1], [translateY, 0]);
      } else if (scrollTop > (down / 100) * window.innerHeight) {
        this.transformSplice(page, [translateY, 0], [0, 1]);
      } else {
        this.transformSplice(page, [0, 1], [0, 1]);
      }
    },
    getLen() {
      return document.querySelectorAll("section").length;
    },
    toAgr() {
      window.location.href = "/test.html";
    },
    scrollToNextSection: function () {
      const sections = document.querySelectorAll("section");
      sections[this.nextSectionIndex].scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      this.nextSectionIndex = (this.nextSectionIndex + 1) % sections.length;
      //这里用下一个应该跳转到的section做index是方便以后新增或减少section的时候代码自适应不涉及到具体的结束数字 同时button的v-class也可以方便的动态变化以判断当前是到下一个section还是返回顶部
    },
    scrollListener(e) {
      const scrollTop =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.scrollY;
      // const scrollDown = scrollTop > this.scrollTop;
      this.scrollTop = scrollTop;
      const maxScrollHeight = document.body.scrollHeight;
      //刚好五个版面 分成五份 但是考虑到页面scrollTop一般情况不会触底所以均减小了1%
      const len = this.getLen();
      //使得当网页中的版面数变化时代码依旧适用
      let points = new Array(len).fill(0).map((r, i) => ({
        point: (1 / len) * i - 0.01,
        nextSectionIndex: (i + 1) % len,
      }));
      for (let i = len - 1; i >= 0; i--) {
        const { point, nextSectionIndex } = points[i];
        if (scrollTop >= point * maxScrollHeight) {
          this.nextSectionIndex = nextSectionIndex;
          break;
        }
      }
      this.currentFadeInPage = this.getPage();
      const pageScrollTop = scrollTop % window.innerHeight;
      const page = (scrollTop - pageScrollTop) / window.innerHeight;
      this.updateTransform(page, pageScrollTop);
      if (this.currentFadeInPage == -1) {
        const set1 = this.fadeState[0];
        const set4 = this.fadeState[4];
        set1.delete("fadeIn");
        set4.delete("fadeIn");
      } else {
        const set = this.fadeState[this.currentFadeInPage];
        set.add("fadeIn");
      }
    },
    reLoadHome: function () {
      this.$router.replace({ name: "Home" });
    },
  },
  watch: {
    scrollTop() {
      if (this.scrollTop <= 5) {
        this.transformState = new Array(5)
          .fill(0)
          .map((r, i) => (i == 0 ? [0, 1] : [config.translateY, 0]));
      }
    },
  },
  mounted() {
    window.requestAnimationFrame(this.cloudMove);
    window.addEventListener("scroll", this.scrollListener);
    window.addEventListener("resize", () => {
      if (!this.isPhone) {
        // 解决苹果浏览器下滑导航栏消失引起的页面resize
        this.screenH = window.innerHeight;
        this.screenW = window.innerWidth;
      }
      if (
        /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
        // ||this.screenH > this.screenW
      ) {
        this.isPhone = true;
      } else {
        this.isPhone = false;
      }
    });
  },
};
</script>

<style>
@import "./assets/css/cssvars.css";
@import "./assets/css/App.css";
@import "./assets/css/window.css";
@import "./assets/css/mobile.css";
@import "./assets/css/stylefix.css";
.header {
  z-index: 10001 !important;
}
</style>
